import "./App.css";
import { base64ToFile, getPage } from "./functions";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa6";
import ReactLoading from "react-loading";
import { MdDelete } from "react-icons/md";
import { Modal } from "./components/Modal";
import { useState, useRef, DragEvent } from "react";
import * as PDFJS from "pdfjs-dist/legacy/build/pdf";
import { GiConfirmed, GiCancel } from "react-icons/gi";
import withReactContent from "sweetalert2-react-content";
import TopLoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { v4 as uuidv4 } from "uuid";
import { Html5Qrcode } from "html5-qrcode";
import { DecodingResult, decodeQrCode } from "./decoder";

PDFJS.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`;

export type DecodingStatus = "pending" | "started" | "complete";

export type DocumentPage = {
  id: string;
  fileName: string;
  documentId?: string;
  decodingStatus: DecodingStatus;
  decoding?: DecodingResult;
  base64: string;
  uploaded: boolean;
  uploadError?: string;
};

export const hasDocumentId = (page: DocumentPage) => {
  return page.documentId !== undefined && page.documentId?.length > 0;
};

function App() {
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<DocumentPage[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEye, setShowEye] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [picIndex, setPicIndex] = useState<number | null>(null);
  const [modalImage, setModalImage] = useState<string | null | undefined>(null);

  const ref = useRef<LoadingBarRef>(null);

  const startLoading = () => {
    ref.current?.continuousStart();
  };

  const finishLoading = () => {
    ref.current?.complete();
  };

  const pageDecodingQueue: DocumentPage[] = [];
  let pageDecodingQueueProcessing = false;

  const pageDecodingQueueAdd = (page: DocumentPage) => {
    pageDecodingQueue.push(page);
    console.log("pageDecodingQueueAdd", pageDecodingQueue);
    pageDecodingQueueProcess();
  };

  const pageDecodingQueueProcess = async () => {
    if (pageDecodingQueueProcessing) {
      console.log("pageDecodingQueueProcess - already processing");
      return;
    }

    pageDecodingQueueProcessing = true;
    try {
      console.log("pageDecodingQueueProcess - ", pageDecodingQueue);
      while (pageDecodingQueue.length > 0) {
        const page = pageDecodingQueue.shift();
        if (page) {
          console.log("pageDecodingQueueProcess - decoding page", page.id);
          await decodePage(page);
          console.log("pageDecodingQueueProcess - page decoded", page.id);
        }
      }
    } finally {
      pageDecodingQueueProcessing = false;
    }
  };

  const decodePage = async (page: DocumentPage) => {
    const file = base64ToFile(page.base64);
    const html5QrCode = new Html5Qrcode("reader");

    const decoders = await decodeQrCode(html5QrCode, file);
    page.decoding = decoders;
    page.decodingStatus = "complete";

    if (decoders.success) {
      page.documentId = decoders.success.value;
    }

    // update the page in the state using id property
    setPages((prev) => prev.map((item) => (item.id === page.id ? page : item)));
  };

  const processUploadFile = async () => {
    startLoading();
    const entryFileInput = document.getElementById(
      "entryFile"
    ) as HTMLInputElement;

    if (entryFileInput!.value === "") {
      alert("Please choose a file to scan.");
      return false;
    }

    setIsDisabled(true);

    try {
      if (entryFileInput && entryFileInput.value && entryFileInput.files) {
        Array.from(entryFileInput.files).forEach(async (uploadFile) => {
          if (uploadFile && uploadFile.type) {
            let imageArray: string[] = [];

            const isPdf = uploadFile.type === "application/pdf";

            if (isPdf) {
              const pdfUrl = URL.createObjectURL(uploadFile);
              try {
                const pdf = await PDFJS.getDocument(pdfUrl).promise;
                for (let i = 1; i <= pdf.numPages; i++) {
                  imageArray.push(await getPage(pdf, i));
                }
              } catch (error: any) {
                alert(error.message);
              }
            } else if (uploadFile.type.startsWith("image/")) {
              const reader = new FileReader();
              const readFilePromise = new Promise<void>((resolve, reject) => {
                reader.onload = function (event) {
                  const base64Image = event!.target!.result;
                  imageArray.push(base64Image as string);
                  resolve();
                };
                reader.onerror = function (error) {
                  reject(error);
                };
                reader.readAsDataURL(uploadFile);
              });
              await readFilePromise;
            }

            let indexPage = 0;
            for (const image of imageArray) {
              indexPage++;

              let fileName = uploadFile.name;
              if (fileName.length > 20) {
                fileName = "..." + fileName.slice(-20);
              }

              const page: DocumentPage = {
                id: uuidv4(),
                fileName: isPdf
                  ? `PDF Page: ${indexPage} -splitText ${fileName}`
                  : `Image -splitText ${fileName}`,
                base64: image,
                uploaded: false,
                decodingStatus: "pending",
              };

              setPages((prev) => [...prev, page]);

              //wait for the page to be added to the state
              await new Promise((resolve) => setTimeout(resolve, 1000));

              pageDecodingQueueAdd(page);
            }
          }
        });
      } else {
        alert("Please provide a valid file");
      }
    } catch (error) {
      console.error(error);
    } finally {
      entryFileInput.value = "";
      setIsDisabled(false);
      finishLoading();
    }
  };

  const alert = (message: any, icon: any = "error") => {
    MySwal.fire({
      icon: icon,
      position: "top",
      title: message,
      showConfirmButton: false,
    });
  };

  const uploadDocuments = async () => {
    if (!pages.length) {
      alert("Please choose a file to upload.");
      return;
    }

    const updatedResults = pages.filter((page) => !page.uploaded);
    if (updatedResults.length <= 0) {
      alert("All pages have been uploaded");
      return;
    }

    for (let i = 0; i < pages.length; i++) {
      if (!pages[i].documentId) {
        alert(`Enter code for ${pages[i].fileName}`);
        return;
      }
    }
    setIsDisabled(true);
    setLoading(true);
    await Promise.all(
      updatedResults.map(async (result, index) => {
        const formData = new FormData();

        // Convert the base64 data to a Blob
        const byteCharacters = atob(result.base64!.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });

        const decoders = result.decoding
          ? JSON.stringify(result.decoding)
          : null;

        formData.append("file", blob, result.documentId + ".png");
        formData.append("description", "Document Scan");
        formData.append("documentId", result.documentId!);

        if (decoders) {
          formData.append("decoders", decoders);
        }

        try {
          const currentUrl = window.location.href;
          const url = new URL(currentUrl);
          const token = url.searchParams.get("token");

          const response = await axios.post(
            `https://api-prod.tradepeg.com/documents/attachments/scan-to-cloud`,
            formData,
            {
              headers: {
                Authorization: token,
                "User-Agent": "TradePeg-ScanToCloud / 1.0.0",
              },
            }
          );

          updatedResults[index] = {
            ...result,
            uploaded: true,
            uploadError: undefined,
          };
        } catch (error: any) {
          const message = error.response?.data?.error || error.message;

          updatedResults[index] = {
            ...result,
            uploaded: false,
            uploadError: message,
          };
        }
      })
    );
    setPages(updatedResults);
    setLoading(false);
    setIsDisabled(false);
  };

  const removeFile = (index: number) => {
    const updatedResults = [...pages];
    updatedResults.splice(index, 1);
    setPages(updatedResults);
  };

  const addCode = (index: number, inputValue: string | null) => {
    if (!inputValue) {
      const inputElement = document.getElementById(
        `codeInput-${index}`
      ) as HTMLInputElement;

      inputValue = inputElement.value;
    }

    const updatedResults = [...pages];

    updatedResults[index] = {
      ...updatedResults[index],
      documentId: inputValue,
    };

    setPages(updatedResults);
  };

  const handleFileDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const inputElement = document.getElementById(
      "entryFile"
    ) as HTMLInputElement;
    const files = e.dataTransfer.files;

    const acceptedFormats = [
      "image/jpeg",
      "image/gif",
      "image/png",
      "application/pdf",
    ];

    if (!files.length) {
      return;
    }

    const validFiles = Array.from(files).filter((file) =>
      acceptedFormats.includes(file.type)
    );

    if (validFiles.length === 0) {
      alert(
        "Dropped files are not in the accepted formats.(Allowed files: jpeg, png, pdf)"
      );
      return;
    }

    if (!inputElement) return;
    inputElement.files = files;

    await processUploadFile();
  };

  const handleChangePic = (i: number, direction: string) => {
    let newIndex = i;

    if (direction === "prev") {
      newIndex--;

      if (newIndex >= 0) {
        setPicIndex(newIndex);
        setModalImage(pages[newIndex].base64);
      }
    } else {
      newIndex++;

      if (newIndex < pages.length) {
        setPicIndex(newIndex);
        setModalImage(pages[newIndex].base64);
      }
    }
  };

  return (
    <div className="-m-1">
      <TopLoadingBar color="#03bf5f" ref={ref} height={3} />
      {showModal && modalImage && (
        <Modal
          image={modalImage}
          index={picIndex}
          setShowModal={setShowModal}
          handleChangePic={handleChangePic}
          results={pages}
          addCode={addCode}
        />
      )}
      <div
        className="formHolder"
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={handleFileDrop}
      >
        <div>
          <div className="homepage">
            <h1 className="text-3xl font-bold text-center">
              TradePeg Scan To Cloud
            </h1>
            <label
              htmlFor="dropContainer"
              className="drop-container"
              id="dropContainer"
            >
              <span className="drop-title">Drop file here</span>
              or
              <input
                id="entryFile"
                type="file"
                multiple
                accept="image/jpeg,image/gif,image/png,application/pdf"
                onChange={processUploadFile}
              />
            </label>
            {pages.length > 0 ? (
              <div>
                <div className="relative w-[340px] h-6 bg-gray-300 rounded-full mb-3">
                  <div
                    className="absolute left-0 top-0 h-6 bg-green-400 rounded-full"
                    style={{
                      width: `${
                        (pages.filter((result) => hasDocumentId(result))
                          .length /
                          pages.length) *
                        100
                      }%`,
                    }}
                  ></div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <span>
                      {pages.filter((result) => hasDocumentId(result)).length}
                      <span className=""> out of </span>
                      {pages.length} found
                    </span>
                  </div>
                </div>
                <div className="buttons">
                  <button
                    onClick={uploadDocuments}
                    disabled={
                      isDisabled ||
                      pages.filter((result) => hasDocumentId(result)).length !==
                        pages.length
                    }
                    id="upload_btn"
                    className="button upload-button bg-blue-500 hover:bg-blue-700 text-white"
                  >
                    Upload files
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-wrap m-5 mt-10">
            {pages.length > 0
              ? pages.map((result, i: number) => (
                  <div
                    key={i}
                    className="relative flex flex-col gap-2 items-center w-full md:w-1/2 xl:w-1/3 2xl:w-1/4 mb-14"
                  >
                    {!loading && (result.uploadError || result.uploaded) && (
                      <div className="absolute top-[170px] mx-10 flex flex-col gap-5 items-center z-[2] p-3">
                        {result.uploadError && (
                          <div className="flex flex-col justify-center items-center bg-white rounded-lg border border-black w-[350px]">
                            <GiCancel size={60} color="red" />
                            <p className="text-center text-red-600 text-xl font-semibold bg-white bg-opacity-70 m-3">
                              {result.uploadError}
                            </p>
                          </div>
                        )}
                        {!result.uploadError && (
                          <GiConfirmed size={60} color="green" />
                        )}
                      </div>
                    )}
                    {loading && (
                      <div className="absolute top-[170px]">
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#b8b8b8"}
                          height={150}
                          width={150}
                        />
                      </div>
                    )}
                    <div
                      className={
                        "flex items-center rounded-t-lg relative shadow" +
                        (hasDocumentId(result)
                          ? " bg-green-200"
                          : " bg-red-200")
                      }
                    >
                      <span className="w-[50px] flex justify-center items-center cursor-pointer"></span>
                      <div className="flex flex-col w-[250px] text-center p-2 text-xl">
                        <p className="">
                          {result.fileName.split("-splitText")[0]}
                        </p>
                        <p> {result.fileName.split("-splitText")[1]}</p>
                      </div>
                      <span>
                        {result.decodingStatus == "pending" && (
                          <div className="absolute left-3 top-1/2 -translate-y-1/2">
                            <ReactLoading
                              type={"spinningBubbles"}
                              color={"#cb1b1b"}
                              height={30}
                              width={30}
                            />
                          </div>
                        )}
                      </span>
                      <button
                        onClick={() => removeFile(i)}
                        className="w-[50px] text-red-500 flex justify-end p-2"
                      >
                        <MdDelete size={25} />
                      </button>
                    </div>
                    <div
                      className="relative"
                      onMouseEnter={() => setShowEye(i)}
                      onMouseLeave={() => setShowEye(null)}
                    >
                      {showEye === i && (
                        <button
                          onClick={() => {
                            setPicIndex(i);
                            setShowModal(true);
                            setModalImage(result.base64);
                          }}
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full p-2"
                        >
                          <FaEye size={40} color="white" />
                        </button>
                      )}
                      <img
                        className="w-[350px] h-[500px] border-[1.5px]"
                        src={result.base64}
                        alt="file"
                      />
                    </div>
                    {hasDocumentId(result) ? (
                      <p className="text-lg h-[60px]">
                        Found:{" "}
                        <span className="font-semibold">
                          {result.documentId}
                        </span>
                      </p>
                    ) : (
                      <div className="flex gap-1 items-start">
                        <div>
                          <input
                            type="text"
                            defaultValue={result.documentId}
                            id={`codeInput-${i}`}
                            placeholder="Enter code"
                            className="shadow"
                          />
                        </div>
                        <button
                          className="bg-blue-500 text-white px-3 py-1.5 rounded shadow font-semibold"
                          onClick={() => addCode(i, null)}
                        >
                          Add
                        </button>
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
          <div id="holder"></div>
        </div>
        <div className="hidden" id="reader"></div>
      </div>
    </div>
  );
}

export default App;
