import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import { DocumentPage, hasDocumentId } from "../App";
import { useState, useEffect, FC } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

interface ModalInterface {
  image: string;
  index: number | null;
  results: DocumentPage[];
  setShowModal: (value: boolean) => void;
  addCode: (i: number, value: string | null) => void;
  handleChangePic: (i: number, direction: string) => void;
}

export const Modal: FC<ModalInterface> = ({
  index,
  image,
  results,
  addCode,
  setShowModal,
  handleChangePic,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    results[index!]?.documentId || ""
  );
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const handleAddCode = () => {
    const inputElement = document.getElementById(
      `codeInput-${index}`
    ) as HTMLInputElement;

    /*     if (isInvalidCode(inputElement.value)) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);

      addCode(index!, inputValue); 
      if (hasNextUnFoundImage(index!)) {
        handleChangePic(index!, "next");
      } else if (hasPreviousUnFoundImage(index!)) {
        handleChangePic(index!, "prev");
      } else {
        setShowModal(false);
      }
    } */

    addCode(index!, inputValue);
  };

  const hasPreviousUnFoundImage = (index: number): boolean => {
    let i = index - 1;
    while (i >= 0) {
      i--;
      return true;
    }
    return false;
  };

  const hasNextUnFoundImage = (index: number): boolean => {
    let i = index + 1;
    while (i < results.length) {
      i++;
      return true;
    }
    return false;
  };

  useEffect(() => {
    setInputValue(results[index!]?.documentId || "");
    setIsInvalid(false);
  }, [index, results]);

  return (
    <div className="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 left-0 z-[1]">
      <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-slate-700 dark:text-slate-200 rounded-sm">
        <div className="relative h-[95vh] w-[100vw] lg:w-[1000px] flex">
          {index !== null && hasPreviousUnFoundImage(index) ? (
            <button
              className="text-[45px] lg:text-[100px]"
              onClick={() => handleChangePic(index, "prev")}
            >
              <MdOutlineArrowBackIosNew />
            </button>
          ) : (
            <div className="w-[45px] lg:w-[100px]"></div>
          )}

          <div className="flex flex-col justify-center items-center gap-0 bg-gray-100 rounded-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute -right-5 -top-5 bg-white rounded-full -p-2 "
            >
              <AiFillCloseCircle color="red" size={45} />
            </button>
            <div className="flex text-center p-2 text-xl text-gray-700">
              <p className="">
                {results[index!].fileName.split("-splitText")[0]}
                {results[index!].fileName.startsWith("PDF") ? "of " : " "}
                {results[index!].fileName.split("-splitText")[1]}
              </p>
            </div>
            <img
              src={image}
              className="h-[83vh] w-[90vw] lg:w-[800px]"
              alt="file"
            />
            <div className="h-[40px] flex justify-center items-center my-2">
              {index !== null && results[index!]?.documentId ? (
                <p className="text-xl text-gray-700">
                  Result: {results[index!]?.documentId}
                </p>
              ) : (
                <div className="flex gap-1 items-start mx-auto h-[40px]">
                  <div className="flex gap-1">
                    {isInvalid ? (
                      <p className="text-red-500">Value entered is invalid</p>
                    ) : (
                      <p className="h-[24px]"></p>
                    )}
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      id={`codeInput-${index}`}
                      placeholder="Enter code"
                      className="text-black"
                    />
                  </div>
                  <button
                    className="bg-blue-500 text-white px-3 py-1.5 rounded font-semibold shadow"
                    onClick={handleAddCode}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
          </div>
          {index !== null && hasNextUnFoundImage(index) ? (
            <button
              className="text-[45px] lg:text-[100px]"
              onClick={() => handleChangePic(index, "next")}
            >
              <MdOutlineArrowForwardIos />
            </button>
          ) : (
            <div className="w-[45px] lg:w-[100px]"></div>
          )}
        </div>
      </div>
    </div>
  );
};
