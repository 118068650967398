import { PDFDocumentProxy } from "pdfjs-dist";

export const getPage = (
  pdf: PDFDocumentProxy,
  pageNumber: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    pdf.getPage(pageNumber).then((page: any) => {
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      page
        .render({ canvasContext, viewport })
        .promise.then(() => {
          const imageData = canvas.toDataURL("image/jpeg");
          resolve(imageData);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  });
};

export const fileToBase64 = (
  file: File
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const base64ToFile = (base64Image: string): File => {
  // Split the base64 data to get the content type and data
  const base64Data = base64Image.split(",")[1];
  const contentType = (base64Image.match(/^data:(.*);base64/) ||
    [])[1] as string;

  // Convert the base64 data to a binary array
  const binaryData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const byteArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: contentType });

  const fileName = "image." + contentType.split("/")[1];

  // Create a File object from the Blob
  return new File([blob], fileName, { type: contentType });
};

export const isInvalidCode = (code: string): boolean => {
  const regexPattern =
    /^(ASN|INSHP|SO|TRN|PO|SO|RFQ|RCT|INV|SQ|TRC|SHP|CRN|PCK|ASMWO|ASM|RMA|BLL|SPK|LPN|EDM)\d{1,8}$/i;
  if (regexPattern.test(code)) {
    return false;
  }
  return true;
};

export const magnify = (imgID: string, zoom: number): void => {
  const img: HTMLImageElement | null = document.getElementById(
    imgID
  ) as HTMLImageElement;

  if (!img) {
    console.error("Image not found");
    return;
  }

  // Create magnifier glass:
  //@ts-ignore
  const glass: HTMLDivElement = document.createElement("DIV");
  glass.setAttribute("class", "img-magnifier-glass");

  // Insert magnifier glass:
  img.parentElement?.insertBefore(glass, img);

  // Set background properties for the magnifier glass:
  glass.style.backgroundImage = `url('${img.src}')`;
  glass.style.backgroundRepeat = "no-repeat";
  glass.style.backgroundSize = `${img.width * zoom}px ${img.height * zoom}px`;

  const bw: number = 3;
  const w: number = glass.offsetWidth / 2;
  const h: number = glass.offsetHeight / 2;

  // Add event listeners for both mouse and touch screens:
  glass.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mousemove", moveMagnifier);

  glass.addEventListener("touchmove", moveMagnifier);
  img.addEventListener("touchmove", moveMagnifier);

  function moveMagnifier(e: MouseEvent | TouchEvent): void {
    e.preventDefault();

    const pos: { x: number; y: number } = getCursorPos(e);
    let x: number = pos.x;
    let y: number = pos.y;
    if (!img) return;
    if (x > img.width - w / zoom) {
      x = img.width - w / zoom;
    }
    if (x < w / zoom) {
      x = w / zoom;
    }
    if (y > img.height - h / zoom) {
      y = img.height - h / zoom;
    }
    if (y < h / zoom) {
      y = h / zoom;
    }

    glass.style.left = `${x - w}px`;
    glass.style.top = `${y - h}px`;

    glass.style.backgroundPosition = `-${x * zoom - w + bw}px -${
      y * zoom - h + bw
    }px`;
  }

  function getCursorPos(e: MouseEvent | TouchEvent): { x: number; y: number } {
    const a: ClientRect = img!.getBoundingClientRect();
    let x: number;
    let y: number;

    if (e instanceof MouseEvent) {
      x = e.pageX - a.left;
      y = e.pageY - a.top;
    } else if (e instanceof TouchEvent && e.touches[0]) {
      x = e.touches[0].pageX - a.left;
      y = e.touches[0].pageY - a.top;
    } else {
      x = 0;
      y = 0;
    }

    x = x - window.pageXOffset;
    y = y - window.pageYOffset;

    return { x, y };
  }
};
