import { Decoder } from "@nuintun/qrcode";
import axios from "axios";
import { Html5Qrcode } from "html5-qrcode";
import jsQR from "jsqr";

export interface DecodingResult {
  success?: {
    name: string;
    value: string;
  };
  failure?: string[];
}

interface DecoderResult {
  name: string;
  success: boolean;
  value?: string;
  error?: string;
}

export async function decodeQrCode(
  reader: Html5Qrcode,
  file: File
): Promise<DecodingResult> {
  let tries: string[] = [];

  console.log(`decodeQrCode - start, name:${file.name}, size:${file.size}`);

  console.log("decodeQrCode - 1");
  let _try = await decodeWithHtml5QrCode(reader, file);
  if (_try.success) {
    console.log(`found with ${_try.name}: ${_try.value}`);
    return { success: { name: _try.name, value: _try.value! }, failure: tries };
  }
  tries.push(_try.name);

  console.log("decodeQrCode - 2");
  _try = await decodeWithDecoder2(file);
  if (_try.success) {
    console.log(`found with ${_try.name}: ${_try.value}`);
    return { success: { name: _try.name, value: _try.value! }, failure: tries };
  }
  tries.push(_try.name);

  console.log("decodeQrCode - 3");
  _try = await decodeWithJsQr(file);
  if (_try.success) {
    console.log(`found with ${_try.name}: ${_try.value}`);
    return { success: { name: _try.name, value: _try.value! }, failure: tries };
  }
  tries.push(_try.name);

  console.log("decodeQrCode - 4");
  _try = await decodeWithDecoderServices(file);
  if (_try.success) {
    console.log(`found with ${_try.name}: ${_try.value}`);
    return { success: { name: _try.name, value: _try.value! }, failure: tries };
  }

  console.log("decodeQrCode - complete");
  return { failure: tries };
}

async function decodeWithDecoderServices(file: File): Promise<DecoderResult> {
  const name = "decoderServices";
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      "https://decoder-services.tradepeg-apps.com/decode-image-qr",
      formData
    );

    if (response.data?.values?.length > 0) {
      return { name, success: true, value: response.data.values[0] };
    }
    return { name, success: false, error: "Could not decode" };
  } catch (error) {
    return { name, success: false, error: `${error}` };
  }
}

async function decodeWithHtml5QrCode(
  reader: Html5Qrcode,
  file: File
): Promise<DecoderResult> {
  const name = "html5-qr-code";
  try {
    const results = await reader.scanFile(file, true);

    if (results) {
      return { name, success: true, value: results };
    }
    return { name, success: false, error: "Could not decode" };
  } catch (error) {
    return { name, success: false, error: `${error}` };
  }
}

async function decodeWithDecoder2(file: File): Promise<DecoderResult> {
  const name = "decoder2-nuintun";
  try {
    const blobUrl = URL.createObjectURL(file);
    const decoder = new Decoder();
    const result = await decoder.scan(blobUrl);

    URL.revokeObjectURL(blobUrl);

    if (result?.data) {
      return { name, success: true, value: result.data };
    }

    return { name, success: false, error: "Could not decode" };
  } catch (error) {
    return { name, success: false, error: `${error}` };
  }
}

async function decodeWithJsQr(file: File): Promise<DecoderResult> {
  const name = "jsqr";
  try {
    const imageData = await getImageDataFromFile(file);

    if (!imageData) {
      return { name, success: false, error: "Could not read image data" };
    }

    const code = jsQR(imageData.data, imageData.width, imageData.height);

    if (code?.data) {
      return { name, success: true, value: code.data };
    }

    return { name, success: false, error: "Could not decode" };
  } catch (error) {
    return { name, success: false, error: `${error}` };
  }
}

const getImageDataFromFile = async (
  file: Blob | MediaSource
): Promise<ImageData | undefined> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext("2d");
      context?.drawImage(img, 0, 0);
      resolve(context?.getImageData(0, 0, img.width, img.height));
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};
